<template>
  <data-chip
    :text="dutyType.title"
    :iconLeft="dutyType.icon"
    :color="dutyType.color"
    :small="small"
  >
  </data-chip>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "duty-type-chip",
  components: {
    DataChip,
  },
  props: {
    dutyType: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
