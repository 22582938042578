<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="reportsOutput"
      item-key="meta.id"
      multi-sort
      sort-by="duration.start.timestamp"
      :sort-desc="false"
      groupBy="groupBy"
      :items-per-page="25"
      :footer-props="footerProps"
      class="elevation-2"
      max-height="800px auto"
      @click:row="routeDetails"
      no-data-text="Keine Berichte vorhanden"
    >
      <template v-slot:top>
        <v-card flat>
          <v-card-subtitle>
            <v-row>
              <v-col v-if="showAllReports">
                <span class="mr-4">Filter nach Berichtsverantwortung</span>
                <v-chip-group
                  v-model="reportAssignedToFilter"
                  mandatory
                  active-class="primary--text"
                >
                  <v-chip
                    label
                    value="all"
                    class="transparent-background font-weight-medium mr-2"
                    >Alle
                  </v-chip>
                  <v-chip
                    label
                    value="own"
                    class="transparent-background font-weight-medium"
                    >Eigene</v-chip
                  >
                </v-chip-group>
              </v-col>
              <v-col>
                <span class="mr-4">Filter nach Status</span>
                <v-chip-group
                  v-model="reportStatusFilter"
                  mandatory
                  active-class="primary--text"
                >
                  <v-chip
                    label
                    value="all"
                    class="transparent-background font-weight-medium mr-2"
                    >Alle</v-chip
                  >
                  <v-chip
                    label
                    value="inProgress"
                    class="transparent-background font-weight-medium mr-2"
                    >In Bearbeitung</v-chip
                  >
                  <v-chip
                    label
                    value="toBeReviewed"
                    class="transparent-background font-weight-medium mr-2"
                    >Zu prüfen</v-chip
                  >
                  <v-chip
                    label
                    value="inCorrection"
                    class="transparent-background font-weight-medium mr-2"
                    >In Korrektur</v-chip
                  >
                  <v-chip
                    v-if="isArchive"
                    label
                    value="approved"
                    class="transparent-background font-weight-medium mr-2"
                    >Genehmigt</v-chip
                  >
                  <v-chip
                    v-if="isArchive"
                    label
                    value="rejected"
                    class="transparent-background font-weight-medium mr-2"
                    >Abgelehnt</v-chip
                  >
                  <v-chip
                    v-if="isArchive"
                    label
                    value="completed"
                    class="transparent-background font-weight-medium mr-2"
                    >Festgeschrieben</v-chip
                  >
                  <v-chip
                    v-if="isArchive"
                    label
                    value="archived"
                    class="transparent-background font-weight-medium mr-2"
                    >Archiviert</v-chip
                  >
                </v-chip-group>
              </v-col>
            </v-row>
          </v-card-subtitle>
        </v-card>
        <v-divider></v-divider>
      </template>
      <template v-slot:group.header="{ items, isOpen, toggle }">
        <th :colspan="headers.length">
          <v-icon @click="toggle" class="mr-1"
            >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
          {{ stripPrefix(items[0].groupBy) }}
          <span class="font-weight-regular grey--text"
            >({{ getCountOfReportsInMonth(items[0].groupBy) }})</span
          >
        </th>
      </template>
      <template #[`item.title`]="{ item }">
        <span :class="!item.title ? 'grey--text' : ''">
          {{ item.title ? item.title : "Nicht festgelegt" }}
        </span>
      </template>
      <template #[`item.assignedTo.uid`]="{ item }">
        <v-chip small
          ><v-icon small left>mdi-account-circle</v-icon>
          {{ item.assignedTo.displayName }}</v-chip
        >
      </template>
      <template #[`item.type.title`]="{ item }">
        <duty-type-chip :dutyType="item.type" small> </duty-type-chip>
      </template>
      <template #[`item.duration.start.timestamp`]="{ item }">
        <span :class="!item.duration.start.timestamp ? 'grey--text' : ''">
          {{
            item.duration.start.timestamp
              ? timestampConverter(item.duration.start.timestamp)
              : "Nicht festgelegt"
          }}
        </span>
      </template>
      <template #[`item.duration.end.timestamp`]="{ item }">
        <span :class="!item.duration.end.timestamp ? 'grey--text' : ''">
          {{
            item.duration.end.timestamp
              ? timestampConverter(item.duration.end.timestamp)
              : item.duration.end.estimatedTimestamp
              ? "Erwartet:"
              : ""
          }}
          <br
            v-if="
              !item.duration.end.timestamp &&
              item.duration.end.estimatedTimestamp
            "
          />
          {{
            item.duration.end.estimatedTimestamp
              ? timestampConverter(item.duration.end.estimatedTimestamp)
              : !item.duration.end.timestamp
              ? "Nicht festgelegt"
              : ""
          }}
        </span>
      </template>
      <template #[`item.status`]="{ item }">
        <report-status-chip :status="item.status" small> </report-status-chip>
      </template>
      <template #[`item.actions`]="{ item }"
        ><v-btn
          v-if="showActionBtn"
          depressed
          @click="openDutyReport(item, 'newTab')"
          small
          ><v-icon left small>mdi-open-in-app</v-icon>Öffnen</v-btn
        >
        <v-menu v-else offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon text v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="routeDetails(item)">
              <v-list-item-icon>
                <v-icon dense>mdi-open-in-app</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Bericht öffnen</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item link @click="openDutyReport(item, 'newTab')">
              <v-list-item-icon>
                <v-icon dense>mdi-open-in-new</v-icon>
              </v-list-item-icon>
              <v-list-item-title
                >Bericht öffnen (in neuem Tab)</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { auth } from "@/firebase";
import DutyTypeChip from "@/components/duty-management/DutyTypeChip.vue";
import ReportStatusChip from "@/components/duty-management/ReportStatusChip.vue";
import { DUTY_MANAGEMENT_REPORTS_read_all } from "@/data/permission-types.js";
export default {
  name: "reports-data-table",
  props: {
    reports: {
      type: Array,
      required: true,
    },
    isArchive: {
      type: Boolean,
      required: false,
      default: false,
    },
    showActionBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    // reportAssignedToFilter: {
    //   type: String,
    //   default: "own",
    // },
  },
  components: {
    DutyTypeChip,
    ReportStatusChip,
  },
  data() {
    return {
      reportStatusFilter: "active",
      reportAssignedToFilter: "own",
      currentUserUid: auth.currentUser.uid,
      footerProps: {
        "items-per-page-options": [15, 25, 50, 100, -1],
      },
    };
  },
  computed: {
    reportsInput() {
      const output = this.reports.map((report) => {
        return {
          ...report,
          groupBy: this.groupByDateText(
            report.duration.start.timestamp.toDate()
          ),
        };
      });
      return output;
    },
    reportsOutput() {
      return this.reportsInput.filter((report) => {
        if (this.reportAssignedToFilter === "own") {
          return report.assignedTo.uid === this.currentUserUid;
        }
        return true;
      });
    },
    showAllReports() {
      return this.$store.getters["organization/checkPermissionByID"](
        `${DUTY_MANAGEMENT_REPORTS_read_all}`
      );
    },
    headers() {
      const headersArray = [
        { text: "Titel", value: "title" },
        { text: "Dienstart", value: "type.title" },
        { text: "Startzeitpunkt", value: "duration.start.timestamp" },
        { text: "Endzeitpunkt", value: "duration.end.timestamp" },
        {
          text: "Berichtsverantwortlich",
          value: "assignedTo.displayName",
        },
        // { text: "Fälligkeit", value: "dueDate" },
        {
          text: "Status",
          value: "status",
          filter: (value) => {
            if (!this.reportStatusFilter) return true;
            if (this.reportStatusFilter === "all") return true;
            return value === this.reportStatusFilter;
          },
        },
        {
          value: "actions",
          sortable: false,
        },
        // { text: "", value: "nextActivity" },
      ];
      return headersArray;
    },
  },
  methods: {
    routeDetails(item) {
      if (!this.showActionBtn) {
        this.openDutyReport(item);
      }
    },
    openDutyReport(item, type = "default") {
      if (type === "newTab") {
        if (item.status === "inProgress") {
          window.open(
            this.$router.resolve({
              name: "duty-management-reports-live",
              params: {
                organizationId: this.$route.params.organizationId,
                itemId: item.meta.id,
              },
            }).href,
            "_blank"
          );
          return;
        } else {
          window.open(
            this.$router.resolve({
              name: "duty-management-reports-details",
              params: {
                organizationId: this.$route.params.organizationId,
                itemId: item.meta.id,
              },
            }).href,
            "_blank"
          );
          return;
        }
      } else {
        if (item.status === "inProgress") {
          this.$router.push({
            name: "duty-management-reports-live",
            params: {
              organizationId: this.$route.params.organizationId,
              itemId: item.meta.id,
            },
          });
        } else {
          this.$router.push({
            name: "duty-management-reports-details",
            params: {
              organizationId: this.$route.params.organizationId,
              itemId: item.meta.id,
            },
          });
        }
      }
    },
    groupByDateText(date) {
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      const monthLong = date.toLocaleString("de-DE", { month: "long" });
      return year + "-" + month + "_" + monthLong + " " + year;
    },
    stripPrefix(item) {
      return item.substring(item.indexOf("_") + 1);
    },
    getCountOfReportsInMonth(monthId) {
      return this.reportsOutput.filter((report) => report.groupBy === monthId)
        .length;
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>
